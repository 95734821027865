.circle {
  border-radius: 50%;
}

.circle.icon {
  width: 156px;
  height: 156px;
}

.grid-container.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: -30px;
}

.grid-container.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-container.content.skills {
  grid-template-columns: 1fr 1fr;
}

.grid-item {
  padding: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  padding-top: 15px;
  padding-bottom: 75px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
}

.section.no-pad {
  padding: 0;
}

.section.no-pad-bot {
  padding-bottom: 0;
}

.section.no-pad-top {
  padding-top: 0;
}

.section > h1 {
  font-size: 18px;
  padding-left: 85px;
}

.section > h2 {
  font-weight: 400;
  font-size: 20px;
  padding-left: 100px;
}

.section h3 {
  font-weight: 400;
  font-size: 14px;
}

.section > .icon {
  width: 84px;
  height: 84px;
  line-height: 84px;
  cursor: default;
  top: -42px;
  position: absolute;
  z-index: 99;
}

.section > .icon > i {
  line-height: 84px;
  font-size: 24px;
}

@media (max-width: 600px) {
  .circle.icon {
    width: 200px;
    height: 200px;
  }

  .grid-item {
    text-align: center;
    flex-direction: column;
    padding: 10px;
  }

  .grid-container.header {
    display: none !important;
  }

  .grid-container.content {
    grid-template-columns: 1fr !important;
  }

  .section > h1 {
    font-size: 18px;
    padding-left: 85px;
  }
  .section > .icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    cursor: default;
    top: -30px;
    left: 10px;
    position: absolute;
  }
  .section > .icon > i {
    line-height: 60px;
    font-size: 24px;
  }
}

@media (min-width: 993px) {
  .section {
    padding-left: 5%;
  }
}

@media (min-width: 1280px) {
  .section {
    padding-left: 10%;
  }
}
