.contact-information {
  padding-top: 60px;
  font-weight: 300;
  line-height: 1.5;
}

.contact-information .icon {
  margin-bottom: 20px;
  height: 60px;
}

.contact-information .icon i {
  color: #333;
  font-size: 48px;
}

@media (max-width: 992px) {
  .contact-information .contact-column {
    margin-bottom: 30px;
    padding-left: 30px;
  }
}
