.portfolio-item-container {
  height: 200px;
  width: 400px;
  display: flex;
  justify-content: center;
  position: relative;
}

.portfolio-item-container:hover {
  scale: 1.05;
  transition: scale .5s ease-out;
}

.portfolio-item-container>a {
  display: block;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.portfolio-item-container p{
  color: white;
}

.overlay-text {
  position: absolute;
  margin: 0 5px;
  padding: 10px;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .portfolio-item-container {
    height: 250px;
  }
  .overlay-text {
    bottom: 15px;
  }
}

img {
  height: 100%;
  width: auto;
}
