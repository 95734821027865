.service-item .icon i {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.service-item {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.service-item .icon {
  margin: 0 auto;
  display: inline-block;
  width: 68px;
  height: 68px;
  position: relative;
}

.service-item .icon i {
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 100%;
  text-align: center;
  font-size: 24px;
  color: #fff;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 10px;
}

.service-item h6 {
  margin-top: 50px;
  font-size: 18px;
  font-weight: 400;
}

.service-item .sep {
  height: 1px;
  width: 90px;
  margin: 20px auto;
  display: block;
}

.service-item p {
  line-height: 22px;
  font-weight: 300;
}

.service-item:hover .icon i {
  width: 68px;
  height: 68px;
  line-height: 68px;
  left: 0;
  top: 0;
  font-size: 30px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
