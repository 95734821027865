/* Materialize Reset */
nav {
  background-color: transparent;
  box-shadow: none;
}

.row {
  margin: 0;
}
/* -------------------------------- */

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body,
html {
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #757575;
}

nav ul li > .active {
  background-color: rgba(0, 0, 0, 0.1);
}

#Services_Carousel,
#Portfolio_Carousel,
#References_Carousel {
  padding-top: 50px;
  margin-top: 30px;
}

section#education,
section#skills,
section#portfolio,
section#references {
  background-color: #fff;
}

section#works,
section#services,
section#price,
section#contact {
  background-color: #fafafa;
}

section#portfolio .swiper-slide {
  justify-content: center;
  display: flex;
}

@media (min-width: 768px) {
  section#portfolio .swiper-wrapper {
    padding-left: 10%;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 12px;
  }
}
