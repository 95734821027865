.social-links > li > a {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.social-links {
  text-align: center;
  padding-top: 20px;
}

.social-links > li {
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
}

.social-links > li > a {
  color: #fff;
  font-size: 20px;
}

.social-links > li > a:hover {
  color: rgb(21, 101, 192);
}

header {
  height: 177px;
  color: #fff;
  position: relative;
}

header .user_name-title {
  padding-left: 30px;
  margin-top: 60px;
}

header .user_name-title h2 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
}

header .user_name-title p {
  font-weight: 100;
  font-size: 14px;
}

@media (max-width: 992px) {
  header .user_name-title {
    text-align: center;
    padding: 30px 20px;
    margin: 0;
  }
  header {
    height: auto;
  }
}
